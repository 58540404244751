<template>
  <div>
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-12">
          <p class="title is-5 has-text-right">
            {{ eCourseTopic.eCourse.schoolClass.name }} -
            {{ eCourseTopic.eCourse.subject.name }}
          </p>
          <p class="subtitle is-6 has-text-right">{{ eCourseTopic.name }}</p>
        </div>
        <div class="column is-12">
          <b-table :data="eCourseTopic.eCourseWorks" hoverable>
            <b-table-column
              field="#"
              label="#"
              width="40"
              numeric
              v-slot="props"
            >
              {{ eCourseTopic.eCourseWorks.indexOf(props.row) + 1 }}
            </b-table-column>

            <b-table-column label="Course Type" v-slot="props">
              {{ props.row.courseType }}
            </b-table-column>

            <b-table-column label="Duration" v-slot="props">
              {{ props.row.duration }}
            </b-table-column>

            <b-table-column label="Published" v-slot="props">
              <figure class="image is-24x24">
                <img
                  src="@/assets/images/square-green.png"
                  alt="avatar"
                  v-if="props.row.published"
                />
                <img src="@/assets/images/square-red.png" alt="avatar" v-else />
              </figure>
            </b-table-column>

            <!-- <b-table-column label="Questions" v-slot="props">
              <router-link
                :to="`/school/${schoolId}/e_course_work/${props.row.id}/questions`"
              >
                Questions
              </router-link>
            </b-table-column> -->

            <b-table-column label="Actions" width="160" v-slot="props">
              <a
                class="is-small has-text-info"
                @click="initializeInfoECourseWork(props.row)"
                style="font-size: 0.9em"
              >
                <fa-icon icon="info-circle" size="lg" />
              </a>
              <a
                class="is-small has-text-success"
                @click="initializeUpdateECourseWork(props.row)"
                style="font-size: 0.9em; margin-left: 16px"
              >
                <fa-icon icon="edit" size="lg" />
              </a>
              <a
                class="is-small has-text-danger"
                @click="initializeDeleteECourseWork(props.row)"
                style="font-size: 0.9em; margin-left: 16px"
              >
                <fa-icon icon="trash-alt" size="lg" />
              </a>
            </b-table-column>
          </b-table>
        </div>
      </div>
      <!-- Delete -->
      <app-modal
        id="remove-comment"
        context="remove"
        @remove="remove"
        :show-modal.sync="isModalOpen"
      />
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser } from '@/assets/js/app_info.js'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      pageTitle: 'E Course Works',
      schoolId: null,
      eCourseTopicId: null,
      eCourseTopic: {
        eCourse: { schoolClass: {}, subject: {} },
        eCourseWorks: [],
      },
      eCourseTopicName: null,
      schoolClassName: null,
      subjectName: null,
      context: 'remove',
    }
  },
  watch: {
    'eCourseTopic.eCourseWorks'(data) {
      this.$store.commit('setSubMenus', [
        {
          name: 'E Course Info',
          route: `/school/${this.schoolId}/e_course_info/${this.eCourseTopic.eCourse.id}`,
        },
        {
          name: 'New',
          route: `/school/${this.schoolId}/e_course_work/new`,
        },
      ])
    },
  },
  methods: {
    initializeInfoECourseWork(eCourseWork) {
      console.log(eCourseWork)
      if (eCourseWork.courseType == 'Multiple Choice') {
        this.$router.push(
          `/school/${this.schoolId}/e_course_work/${eCourseWork.id}/multiple_choice`
        )
      } else {
        this.$router.push(
          `/school/${this.schoolId}/e_course_work/${eCourseWork.id}/questions`
        )
      }
      // this.$router.push(
      //   `/school/${this.schoolId}/e_course_work/${eCourseWork.id}`
      // )
    },
    initializeUpdateECourseWork(eCourseWork) {
      this.$router.push(
        `/school/${this.schoolId}/e_course_work/${eCourseWork.id}`
      )
    },
    initializeDeleteECourseWork(eCourseWork) {
      this.eCourseWorkId = eCourseWork.id
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteECourseWorkQuery($id: Int!) {
              deleteECourseWork(input: { id: $id }) {
                eCourseWork {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.eCourseWorkId),
          },
        })
        .then((response) => {
          const data = response.data.deleteECourseWork
          crudNotification(data.errors, 'Successfully deleted.').then(() => {
            this.closeModal()

            this.$apollo.queries.eCourseTopic.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.skipQuery = false
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)
    this.eCourseTopicId = parseInt(this.$route.params.e_course_topic_id)

    this.$store.commit('setValue', this.eCourseTopicId)

    sessionStorage.setItem('eCourseTopicId', this.eCourseTopicId)

    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id
    })

    this.$apollo.addSmartQuery('eCourseTopic', {
      query: gql`
        query ECourseTopicQuery($id: Int!) {
          eCourseTopic(id: $id) {
            id
            name
            eCourse {
              id
              schoolClass {
                name
              }
              subject {
                name
              }
            }
            eCourseWorks {
              id
              courseType
              duration
              published
              questions
            }
          }
        }
      `,
      variables() {
        return { id: this.eCourseTopicId }
      },
    })

    this.$apollo.queries.eCourseTopic.refetch()
  },
}
</script>
